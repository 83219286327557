
import Vue from 'vue'
export default Vue.extend({

    computed: {
        tenantId() {
            return this.$store.getters['projectForm/tenantIdClient']
        },
        licences() {
            return this.$store.getters['projectForm/nbLicences']
        },
        itsm() {
            return this.$store.getters['projectForm/itsm']
        },
        genesysId() {
            return this.$store.getters['projectForm/genesysDeploymentId']
        }
    }
    
})
